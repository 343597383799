<template>
  <!-- Landing section -->
  <section class="main-section">
    <img
      alt="Game Logotype"
      src="../tinified/logo-high.png"
      width="716"
      height="420"
      class="logo"
    />
    <a href="https://furryworld.itch.io/game" class="call-to-action">
      Download on itch.io
    </a>

    <div class="bg">
      <img
        alt="Background"
        src="../tinified/bg_00.png"
        width="1920"
        height="1078"
      />
    </div>

    <div class="bg">
      <video autoplay muted loop>
        <source src="./bg_video.mp4" type="video/mp4" />
      </video>
    </div>
  </section>

  <!-- Middle section -->
  <section class="content-section">
    <div>
      <h1 id="about">Play With Your Friends And Meet Other People</h1>
      <p>
        Furry World is a sandbox multiplayer social game that takes place in a
        world full of anthropomorphic animals. Explore the world and interact
        with other people, customize your furry character and participate in fun
        activities!
      </p>
      <p>
        <b>
          Platform: Windows/Mac <br />
          Developer: Main Leaf
        </b>
      </p>
    </div>
    <div>
      <div class="gameplay-video-container">
        <iframe
          src="https://www.youtube.com/embed/TLqjwOOZ64s"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </section>

  <!-- Lower section -->
  <section class="content-section">
    <div>
      <h1>Discover A World Of Fun And Adventure</h1>
      <p>
        <em>Explore </em>
        The Furry World is full of different places to explore and secrets to be
        found!
      </p>
      <p>
        <em>Customize </em>
        Change your fur and eye color to create a unique look!
      </p>
      <p>
        <em>Fish</em>
        Test your fishing skills against yourself or other players!
      </p>

      <vue-picture-swipe
        class="picture-swipe"
        :items="[
          {
            src: 'screenshots/screenshot_00.png',
            thumbnail: 'screenshots/screenshot_00_thumb.png',
            w: 1908,
            h: 978,
            title: 'Game Screenshot',
          },
          {
            src: 'screenshots/screenshot_01.png',
            thumbnail: 'screenshots/screenshot_01_thumb.png',
            w: 1908,
            h: 978,
            title: 'Game Screenshot',
          },
        ]"
      ></vue-picture-swipe>
    </div>
    <img
      alt="In-game Furry Model"
      src="../tinified/furry.png"
      width="488"
      height="1200"
      class="big-furry"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import VuePictureSwipe from "vue-picture-swipe";

export default defineComponent({
  name: "Home",
  components: {
    VuePictureSwipe,
  },
});
</script>

<style scoped lang="scss">
.main-section {
  $toolbar-height: 85px;

  padding: 0;
  margin: 0;
  height: calc(100vh - #{$toolbar-height});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .call-to-action {
    margin: 60px;
  }

  .logo {
    margin-top: 5%;
    height: 40%;
    max-width: 90%;
    object-fit: contain;
  }

  .bg {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    overflow: hidden;

    & > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    & > video {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }
}

.content-section {
  & > img {
    width: 100%;
    height: 100%;
  }

  .gameplay-video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    width: 100%;

    & > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  .big-furry {
    margin: 0px;
    margin-bottom: -90px;
    width: 40vw;
    max-height: 820px;
    object-fit: contain;
  }

  &:nth-of-type(2) {
    background-color: #e5e5e5;
    background-image: url("../tinified/bg_01.png");
  }

  &:nth-of-type(3) {
    background-color: #dadbdb;
    background-image: url("../tinified/bg_02.png");

    flex-direction: row-reverse;
    align-items: flex-end;
  }
}
</style>
